import { defineStore } from 'pinia';
export const useValidateStore = defineStore('validate-store', {
    state: () => {
        return {
            forms: [],
        };
    },
    getters: {
        getForms: (state) => state.forms,
    },
    actions: {
        setInput(formKey, inputId, name, val, type, rules) {
            const input = {
                id: inputId,
                name: name,
                value: val,
                type: type,
                error: false,
                error_message: '',
                rules: rules,
            };
            if (!this.forms.find((form) => form.key === formKey)) {
                const newForm = { key: formKey, inputs: [] };
                this.forms.push(newForm);
            }
            this.forms = this.forms.map((form) => {
                if (form.key === formKey) {
                    form.inputs.push(input);
                }
                return form;
            });
        },
        removeInput(formKey, inputId) {
            this.forms = this.forms.map((form) => {
                if (form.key === formKey) {
                    form.inputs = form.inputs.filter((input) => input.id !== inputId);
                }
                return form;
            });
        },
        updateInput(formKey, inputId, val) {
            this.forms = this.forms.map((form) => {
                if (form.key === formKey) {
                    form.inputs = form.inputs.map((input) => {
                        if (input.id === inputId) {
                            input.value = val;
                            input.error = false;
                            input.error_message = '';
                        }
                        return input;
                    });
                }
                return form;
            });
        },
        setErrorStatus(formKey, inputId, val) {
            this.forms = this.forms.map((form) => {
                if (form.key === formKey) {
                    form.inputs = form.inputs.map((input) => {
                        if (input.id === inputId) {
                            input.error = val;
                        }
                        return input;
                    });
                }
                return form;
            });
        }
    },
});
